import { Component } from '@angular/core';
import { Platform, NavController, ModalController } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConexionApiService } from './conexion-api.service';
import { UpdateService } from './services/update.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { environment } from '../environments/environment';
import { AyudaComponent } from './modals/ayuda/ayuda.component';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  telefono:any = environment.contacto.wsp;
  texto_contacto:any = environment.contacto.texto;
  telefonocall:any = "tel:" + environment.contacto.telefonocall;
  flag_ayuda: any = "";

  constructor(
    private platform: Platform,
    private device: DeviceDetectorService,
    private nav: NavController,
    public api: ConexionApiService,
    public sw: UpdateService,
    public router: Router,
    private locationStrategy : LocationStrategy,
    private modal: ModalController
  ) {

    // block back button 
    this.preventBackButton();

    //  evitar install banner 
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
    });

    this.sw.checkForUpdates();

    
    // con esta funcion me aseguro de verificar que esté
    //  en un mobile durante toda la app
    /*

    if (this.device.isDesktop()) {
      this.nav.navigateRoot('/browser');
    } else {
      this.initializeApp();
    }
    // debug viewport

    let overlay:HTMLElement = document.createElement('div');
    */

    //this.api.presentToast("DPR:" +window.devicePixelRatio + " RES:" + window.innerWidth + "x" + window.innerHeight, 5);
  }
  async AyudaModal() {
    this.flag_ayuda = await Storage.get({ key: 'flag_ayuda' });
    console.log(this.flag_ayuda.value);
    
    if (this.flag_ayuda.value == null || this.flag_ayuda.value == "0") {
      console.log("modal");
      await Storage.set({
        key: 'flag_ayuda', 
        value: "1"
      });
      const modal = await this.modal.create({
        component: AyudaComponent
      });
      return await modal.present();
    } else {
      console.log("dismmis");
      await Storage.set({
        key: 'flag_ayuda', 
        value: "0"
      });
      const closeModal: string = "Modal Closed";
      await this.modal.dismiss(closeModal);
    }
  }

  public ayudaWhatsappButton(){
    this.api.btnWspAyuda();
  }

  public preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

  initializeApp() {
    // show app... don't touch
    this.platform.ready().then(() => {
      console.log("ready");
    });
  }
}
